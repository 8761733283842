// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'bldnappsstage',
    appId: '1:1083534058718:web:2af8c80e69d7924b5c8e4f',
    storageBucket: 'bldnappsstage.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyCUtuRi9aymGdn5SNxROGeRZA8QibCWdiE',
    authDomain: 'bldnappsstage.firebaseapp.com',
    messagingSenderId: '1083534058718',
    measurementId: 'G-2S3SGR9DLX',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
