import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../shared/services/login.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router, private loginService: LoginService) { }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // console.log('AuthGuard#canActivate called');
    const url: string = state.url;

    const loginUrl = this.router.parseUrl('/home/login');


    if (this.loginService.getIsLoggedIn()) {
      //  console.log('User is logged in');

      return true;
    } else {
      // console.log('User NOT logged in');
      return loginUrl;
    }


  }



}
