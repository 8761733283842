/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public redirectUrl = '';

  private isLoggedIn = false;


  constructor(
    private router: Router,
  ) { }


  getIsLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  logIn() {
    this.isLoggedIn = true;
  }

  logout() {

    this.router.navigate(['/home/login']);
    //this.isLoggedIn = false;

  }




}
